:root {
  --color-white: #fff;
  --color-Neutral-13: #0e1419;
  --color-Cyan-08: #2ab7c0;
  --color-Blue-07: #1e52c8;
  --color-Yellow-07: #c1c346;
  --global-foreground-text-text-secondary: #e2eaf0;
  --input-placeholder-default: #9aa7b2;
  --gradient-button-button-secondary-default: linear-gradient(
    90deg,
    #196e73 0%,
    #55c5cd 100%
  );
  --input-stroke-default: #2d4354;
  --corner-radius-none: 542px;
  --corner-radius-s: 8px;
  --side-padding: 6%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#root {
  font-family: 'League Spartan', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
}

h1 {
  font-size: 68px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin: 5px 0;
}

h2 {
  font-family: 'League Spartan', sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 120%;
  margin: 5px 0;
}

h3 {
  font-family: 'League Spartan', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  margin: 5px 0;
  line-height: 100%;
}

h4 {
  font-family: 'League Spartan', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
  margin: 5px 0;
  text-align: center;
}

h5 {
  font-family: 'League Spartan', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  margin: 5px 0;
}

hr.vertical {
  height: 36px;
  width: 0.5px;
  padding: 4px 0;
  border: none;
}

hr.horizontal {
  border: none;
  height: 0.5px;
  width: 100%;
}

a:link,
a:visited {
  color: inherit;
  text-decoration: none;
}

span.text-special {
  text-align: center;
  font-family: 'League Spartan', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
}
span.text-grey {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.button {
  width: 240px;
  height: 49px;
  border-radius: var(--corner-radius-s);
  background: var(--gradient-button-button-secondary-default);
}

.shield-lock {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-bottom: 4px;
}

.decorations {
  position: absolute;
  top: -200px;
  left: 300px;
  width: 850px;
  height: 755px;
  display: flex;
  flex-direction: row;
  z-index: -1;

  .ellipse-blue {
    width: 200px;
    height: 657px;
    transform: rotate(39deg);
    border-radius: 657px;
    opacity: 0.24;
    background: var(--color-Blue-07);
    filter: blur(100px);
  }

  .ellipse-cyan {
    width: 200px;
    height: 657px;
    transform: rotate(39deg);
    border-radius: 657px;
    opacity: 0.24;
    background: var(--color-Cyan-08);
    filter: blur(100px);
  }
}

.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
}

.flag-norway {
  background-image: url('../public/icons/flag-norway.svg');
  height: 16px;
}

.App {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  justify-content: space-between;
  align-items: flex-start;
  margin: auto;
  flex: 1 1 auto;

  .back-button {
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 8px;
    font-weight: 500;
    position: absolute;
    left: 6%;

    img {
      width: 36px;
      margin-bottom: 5px;
    }
  }

  .page {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    width: 100%;

    h1 {
      text-align: center;
    }
  }

  .section {
    width: 100%;
    padding: 0 var(--side-padding) 48px var(--side-padding);

    &-terms {
      padding-top: 45px;
      border-radius: 64px 64px 0 0;

      .about {
        align-self: start;
      }

      table {
        border-collapse: collapse;

        tbody > *:first-child th {
          border-radius: 24px 24px 0 0;
        }
        tbody > *:last-child th {
          border-radius: 0 0 24px 24px;
        }

        th {
          font-weight: bold;
          width: 300px;
          text-align: left;
          vertical-align: top;
          padding: 36px 18px;
        }

        td {
          padding: 18px;
        }

        ol {
          padding-top: 8px;
        }
      }

      table.account-deletion {
        border-collapse: separate;
        border-spacing: 0 16px;
        width: 100%;

        tbody > * th {
          border-radius: 24px 0 0 24px;
        }
        tbody > * td {
          border-radius: 0 24px 24px 0;
        }

        tr {
          background-color: #f1f5f7;
        }

        th {
          width: 250px;
          font-weight: 400;
          vertical-align: middle;
          position: relative;
        }

        th::after {
          content: '';
          position: absolute;
          top: 15%;
          right: 0;
          bottom: 0;
          width: 1px;
          height: 70%;
          background-color: #d0d9e0;
        }

        td {
          text-align: center;
          vertical-align: middle;
        }

        .delete-account-image {
          width: 350px;
          padding-top: 20px;
        }
      }
    }

    &-conduct {
      border-radius: 0 0 64px 64px;

      h2 {
        text-align: center;
      }

      .grid {
        display: grid;
        gap: 16px;
        grid-template-columns: 1fr 1fr;
        width: 100%;

        .code-point {
          display: flex;
          gap: 16px;
          padding: 16px;
          flex-direction: row;
          border-radius: 16px;

          .text {
            flex: 1;
            align-self: center;
          }

          .code-number {
            border-radius: 12px;
            width: 72px;
            font-weight: 600;
            font-size: 40px;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    &-landing {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      width: 100%;

      .theme-switch {
        hr {
          height: 30px;
        }
      }

      .app-stores {
        display: flex;
        gap: 16px;
        text-align: center;

        .app-link {
          width: 240px;
          height: 75px;
        }
      }

      .success-message {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .about {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 40px;

        b {
          font-size: 24px;
          font-weight: 600;
        }

        div {
          width: 220px;
        }

        .contact-container {
          padding: 4px 8px;
          width: 141px;
          display: flex;
          align-items: center;
          gap: 4px;
          border-radius: var(--corner-radius-s);
        }

        .flag {
          text-align: end;

          .text {
            display: flex;
            align-items: center;
            justify-content: end;
            gap: 8px;
          }
        }
        .person {
          text-align: center;
        }

        .contact-label {
          width: 140px;
          text-align: center;
        }

        .contact {
          width: 220px;
          display: flex;
          flex-direction: column;
          justify-content: start;
          text-align: start;
        }
      }

      .email-form {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: center;

        .email-input {
          width: 345px;
        }

        .button-wrapper {
          width: 240px;
          display: flex;
          flex-direction: column;

          p {
            font-family: 'League Spartan', sans-serif;
            font-size: 12px;
            font-weight: 400;
            color: #9aa7b2;
          }
        }
      }
    }

    &-about {
      margin-top: 64px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 16px;
      align-items: center;

      .dotted-arrow-image {
        pointer-events: none;
        user-select: none;
      }

      > div {
        max-width: 35%;
      }

      .phone-container {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0;

        & > img {
          width: 135px;
          height: 32px;
        }
      }

      .calendar {
        max-width: 450px;
      }
      .map {
        max-width: 450px;
        padding-left: 40px;

        .map-image {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .icon {
        height: 18px;
        background-position-x: center;
      }

      .action-button {
        border-radius: 100px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        height: 48px;
        font-family: 'League Spartan', sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 130%;
        border: none;
        padding-left: 15px;

        i {
          height: 100%;
          width: 45px;
          position: absolute;
          bottom: -1px;
          right: -1px;
        }
      }
    }

    &-media {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 48px;
      padding-bottom: 95px;
      border-radius: 64px 64px 0 0;

      a:link,
      a:visited {
        color: #fff;
        text-decoration: none;
      }

      .article-container {
        width: 100%;
        margin-bottom: 16px;

        > div {
          display: flex;
          flex-direction: row;
          width: 100%;
          margin-bottom: 12px;
          gap: 16px;

          .date {
            font-size: 16px;
            min-width: 145px;
          }
        }

        .date {
          width: 110px;
          font-size: 16px;
        }

        img {
          height: 200px;
          width: 240px;
          border-radius: 16px;
          object-fit: cover;
          object-position: center;
          pointer-events: none;
        }

        .card {
          min-height: 200px;
          width: 100%;
          padding: 16px;
          border-radius: 16px;
          margin-bottom: 16px;

          h3 {
            font-size: 24px;
          }

          .text {
            font-size: 18px;
          }

          > span {
            margin-bottom: 18px;
          }

          .bottom-section {
            display: flex;
            margin-top: 16px;
            justify-content: space-between;
            align-items: center;

            .button {
              width: 103px;
              height: 40px;
              border-radius: 8px;
              font-weight: 500;
              text-transform: none;
              font-size: 16px;
              text-align: center;
            }

            div {
              display: flex;
              align-items: center;
              gap: 8px;

              img {
                width: 32px;
                height: 32px;
                border: none;
              }
            }
          }
        }
      }
    }

    &-posts {
      border-radius: 64px;
      padding: 48px var(--side-padding);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 44px;
      position: relative;
      top: -50px;

      .section-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
      }

      .notes {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 16px;

        .note {
          display: flex;
          width: 550px;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
          border-radius: 14px;

          .top-bar {
            padding: 16px 16px 10px 16px;
            border-radius: 12px 12px 0 0;
            height: 56px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 8px;

            span.text-grey {
              min-width: 45px;
              text-align: end;
            }
          }

          &-body {
            padding: 0 16px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: space-between;

            div {
              width: 100%;
            }

            .no-bots-image {
              width: 100%;
              height: auto;
              aspect-ratio: 17/10;
            }
            .cube-image {
              width: 100%;
              height: auto;
              aspect-ratio: 16/9;
            }
            .no-ads-image {
              width: 100%;
              height: auto;
              aspect-ratio: 16/9;
            }
          }

          .reactions {
            padding-top: 8px;
          }
        }
      }
    }
  }

  .text-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .rotate-180 {
    transform: rotate(-180deg);
  }

  .app-logo-small {
    width: 120px;
    height: 45px;
    pointer-events: none;
  }

  .theme-switch-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer {
    margin-top: 24px;
    padding: 0 6%;
    font-size: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .privacy-links {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
  }

  .footer-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 32px
  }

}

@media (max-width: 1140px) {
  .section {
    padding: 0 40px;

    &-conduct {
      padding: 0 20px !important;

      div {
        .column {
          .code-point {
            height: 100% !important;

            .code-number {
              padding: 6px;
              font-size: 32px;
            }
          }
        }
      }
    }
  }
  .dotted-arrow-image {
    margin-left: 10% !important;
  }

  .calendar {
    .calendar-image {
      width: 300px;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

@media (max-width: 900px) {
  h5 {
    font-size: 16px;
  }
  .App {
    .section {
      &-conduct {
        div {
          flex-direction: column;
          font-size: 16px;

          .row {
            flex-direction: column;
            gap: 12px;

            .code-point {
              flex-direction: column;
              width: 100%;
              padding: 12px;

              .code-number {
                width: 100%;
                font-size: 30px;
                padding: 6px;
                border-radius: 8px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  h1 {
    font-size: 48px;
  }
  h2 {
    font-size: 36px;
  }
  h3 {
    font-size: 32px;
  }
  h4 {
    font-size: 20px;
    line-height: 130%;
  }
  h5 {
    font-size: 20px;
  }
  hr.vertical {
    height: 24px;
  }

  .App {
    justify-content: space-between;
    padding: 20px 0;

    .page {
      font-size: 16px;

      h1 {
        font-size: 36px;
      }
    }

    .decorations {
      position: absolute;
      top: -400px;
      left: 100px;
      width: 850px;
      height: 755px;
      display: flex;
      flex-direction: row;
      z-index: -1;
    }

    .section {
      &-terms {
        padding: 30px 20px;

        table,
        thead,
        tbody,
        td,
        th,
        tr {
          display: block;
          width: 100%;
        }

        table {
          width: 100%;
          border-collapse: collapse;
          table-layout: auto;

          tbody > *:first-child th {
            border-radius: 16px;
          }
          tbody > *:last-child th {
            border-radius: 16px;
          }

          th {
            word-wrap: break-word;
            padding: 16px 16px 16px 16px;
            width: 100%;
            border-radius: 16px;
          }

          td {
            padding: 8px;
            word-wrap: break-word;
            min-width: 100px;
            width: 100%;
          }

          tr {
            margin-bottom: 16px;
          }

          ul,
          ol {
            padding-left: 20px;
          }
        }

        table.account-deletion {
          .delete-account-image {
            width: 220px;
          }
        }
      }

      &-conduct {
        .grid {
          display: grid;
          gap: 16px;
          grid-template-columns: 1fr;
          width: 100%;

          .code-point {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 12px;

            .text {
              flex: 1;
              align-self: flex-start;
            }

            .code-number {
              font-size: 30px;
              display: flex;
              flex: 1;
              width: 100%;
              padding: 6px;
            }
          }
        }
      }

      &-landing {
        padding-bottom: 16px;

        .theme-switch {
          justify-content: space-around;

          hr.vertical {
            height: 24px;
            margin: 0;
          }

          .app-logo-small {
            width: 100px;
            height: 35px;
            pointer-events: none;
          }
        }

        .about {
          justify-content: center;
          flex-wrap: wrap;
          gap: 8px;

          .flag {
            text-align: center;

            .text {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 8px;
            }
          }
          .person {
            text-align: center;
          }

          .contact-label {
          }

          .contact-container {
            padding: 4px 8px;
            width: 141px;
            display: flex;
            align-items: center;
            gap: 4px;
            border-radius: var(--corner-radius-s);
          }

          .contact {
            width: 343px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            text-align: center;
          }

          hr {
            border: none;
            height: 0.5px;
            width: 100%;
            padding: 0;
          }
        }

        .app-stores {
          flex-direction: column;
        }
      }

      &-about {
        flex-direction: column;
        gap: 56px;
        margin-top: 0;
        padding-top: 16px;
        margin-bottom: 48px;

        > div {
          max-width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;

          &:nth-child(1) {
            order: 2;
          }
          &:nth-child(2) {
            order: 1;
          }
          &:nth-child(3) {
            order: 3;
          }
        }
        .dotted-arrow-image {
          display: none;
        }

        .calendar {
          width: 100%;

          .calendar-image {
            width: 354px;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .map {
          width: 100%;
          padding-left: 0;

          .map-image {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }

      &-media {
        order: 2;
        border-radius: 24px 24px;
        padding-bottom: 48px;

        .article-container {
          > div {
            flex-direction: column;

            > img {
              height: 320px;
              width: 100%;
            }

            .card {
              width: 100%;

              h3 {
                font-size: 24px;
              }

              > span {
                margin-bottom: 24px;
              }
            }
          }
        }

        > div {
        }
      }

      &-posts {
        order: 4;
        gap: 24px;
        border-radius: 24px;
        padding-bottom: 0;

        h2 {
          font-size: 32px;
          text-align: center;
        }

        .notes {
          flex-direction: column;

          .note {
            width: 100%;
          }
        }
      }
    }
    .footer-links {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: start;
      width: 100%;
      gap: 32px;
    }
    .footer-links #container {
      width: 100%;
    }

    .privacy-links {
      display: flex;
      flex-direction: row;
      font-size: 16px;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      gap: 32px;
    }

    .footer {
      margin-top: 24px;
      order: 5;
      align-items: start;
      font-size: 14px;
      gap: 32px;
      flex-direction: column;
    }
  }
}

@media (max-width: 700px) {
  .App {
    .back-button {
      display: none;
    }
  }
}
